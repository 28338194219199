import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable'
import { assign, createMachine } from 'xstate'
import {
  BackgroundImageHeader as BackgroundImageHeaderComponent,
  Button,
  ButtonLoading,
  ButtonRound,
  Checkbox,
  FormFieldError,
  FormGroup,
  Input,
  Listbox,
  Meal as MealComponent,
  MealCarousel as MealCarouselComponent,
  MealImage,
  MealWithExtra as MealWithExtraComponent,
  Modal,
  ModalHeader,
  Textarea,
  TextImageStack as TextImageStackComponent,
  TrashIcon,
  TwoMealPicker as TwoMealPickerComponent,
  XIcon,
  APIErrorDisplay,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import {
  cloneDeep,
  compact,
  findIndex,
  flatMap,
  forEach,
  isEmpty,
  map,
  orderBy,
} from 'lodash-es'
import { Controller, useForm } from 'react-hook-form'
import { CSS } from '@dnd-kit/utilities'
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  UniqueIdentifier,
  useDraggable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { Fragment, ReactNode, useCallback, useState } from 'react'
import {
  getCDNHost,
  MenuComponentAnimatedMealCarousel,
  MenuComponentMeal,
  MenuComponentMealWithExtra,
  MenuComponents,
  MenuComponentTextBanner,
  MenuComponentTextImageStack,
  MenuComponentTwoMealPicker,
} from '@tovala/browser-apis-cdn'
import {
  MenuComponentStandardized,
  MenuComponentStandardizedBackgroundImageHeader,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTextBanner,
  MenuComponentStandardizedTextImageStack,
  MenuComponentStandardizedTextImageStackChildren,
  MenuComponentStandardizedTwoMealPicker,
  MenuComponentsStandardized,
  getMenuMealComponents,
} from '@tovala/browser-apis-menu-components'
import {
  ErrorCodeMessageMapCombinedAPI,
  MealAdmin,
  TermSubTerm,
  useTerm,
} from '@tovala/browser-apis-combinedapi'
import { useDropzone } from 'react-dropzone'
import { useMachine } from '@xstate/react'
import { useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'

import {
  useGetMeal,
  useTermMealSummaries,
  useTermMenuComponents,
} from 'hooks/combinedAPI/menus'
import { makeGenericFallbackError } from 'utils/errors'

type DraggableNewComponent = {
  componentType:
    | MenuComponentStandardizedBackgroundImageHeader['type']
    | MenuComponentStandardizedMealWithExtra['type']
    | MenuComponentStandardizedTextImageStack['type']
}

type ViewType = 'desktop' | 'mobile'

const GET_MEAL_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  Fallback: makeGenericFallbackError({
    action: 'find this meal',
  }),
}

const TermMenusV2 = () => {
  const { termID: termIDParam } = useParams<{ termID: string }>()
  const termID = Number.isNaN(Number(termIDParam))
    ? undefined
    : Number(termIDParam)

  const { orderedSubTerms, term } = useOrderedSubTerms({ termID })

  const { termResolvedMenuComponents } = useTermResolvedMenuComponents({
    orderedSubTerms,
    termSpecialEvent: term?.special_event,
  })

  return termResolvedMenuComponents ? (
    <Menus
      orderedSubTerms={orderedSubTerms}
      termResolvedMenuComponents={termResolvedMenuComponents}
    />
  ) : null
}

export default TermMenusV2

const Menus = ({
  orderedSubTerms,
  termResolvedMenuComponents,
}: {
  orderedSubTerms: TermSubTerm[]
  termResolvedMenuComponents: MenuComponentsStandardized[]
}) => {
  const [placeholderState, placeholderSend] = useMachine(placeholderMachine)
  const onClearPlaceholder = useCallback(() => {
    placeholderSend({ type: 'clearPlaceholder' })
  }, [placeholderSend])
  const onInsertPlaceholder = useCallback(
    (index: number) => {
      placeholderSend({ index, type: 'setPlaceholder' })
    },
    [placeholderSend]
  )

  const {
    addComponent,
    components,
    onChangeSelectedSubTermIndex,
    removeComponent,
    reorderComponents,
    selectedComponents,
    selectedSubTerm,
    selectedSubTermIndex,
    updateComponent,
  } = useMenuComponentsForSubterm({
    orderedSubTerms,
    placeholderIndex: placeholderState.context.placeholderIndex,
    termResolvedMenuComponents,
  })

  const [dragOverlayAddComponentType, setDragOverlayAddComponentType] =
    useState<DraggableNewComponent['componentType'] | null>(null)
  const [activeID, setActiveID] = useState<UniqueIdentifier | null>(null)
  const getIndex = (id: UniqueIdentifier) =>
    findIndex(selectedComponents, (component) => component.id === id)
  const activeIndex = activeID ? getIndex(activeID) : -1
  const activeComponent = selectedComponents[activeIndex]

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the component.
      activationConstraint: {
        distance: 10,
      },
    })
  )

  const [imageFilenames, setImageFilenames] = useState(
    new Map<string, string>()
  )

  function onClickDownload() {
    const imageBaseURL = `${getCDNHost()}/menu/images/`

    orderedSubTerms.forEach((subTerm) => {
      const cdnComponents: MenuComponents['components'] = []
      const componentsForSubTerm = components[subTerm.id]

      componentsForSubTerm.forEach((component) => {
        if (component.type === 'meal') {
          cdnComponents.push(makeMealComponent(component))
        } else if (component.type === 'mealWithExtra') {
          cdnComponents.push(makeMealWithExtraComponent(component))
        } else if (component.type === 'twoMealPicker') {
          cdnComponents.push(makeTwoMealPickerComponent(component))
        } else if (component.type === 'animatedMealCarousel') {
          cdnComponents.push(makeAnimatedMealCarouselComponent(component))
        } else if (component.type === 'backgroundImageHeader') {
          const { id, properties, type } = component
          const imageFilename = imageFilenames.get(properties.image.url)
          const imageURL = imageFilename
            ? `${imageBaseURL}${imageFilename}`
            : properties.image.url

          cdnComponents.push({
            id,
            properties: {
              ...properties,
              image: { url: imageURL },
            },
            type,
          })
        } else if (component.type === 'textBanner') {
          cdnComponents.push(makeTextBannerComponent(component))
        } else if (component.type === 'textImageStack') {
          const { id, properties, type } = component

          let image: { url: string } | undefined = undefined
          if (properties.image?.url) {
            const imageFilename = imageFilenames.get(properties.image.url)
            const imageURL = imageFilename
              ? `${imageBaseURL}${imageFilename}`
              : properties.image.url

            image = { url: imageURL }
          }

          const children: MenuComponentTextImageStack['properties']['children'] =
            []

          properties.children.forEach((child) => {
            if (child.type === 'meal') {
              children.push(makeMealComponent(child))
            } else if (child.type === 'mealWithExtra') {
              children.push(makeMealWithExtraComponent(child))
            } else if (child.type === 'twoMealPicker') {
              children.push(makeTwoMealPickerComponent(child))
            } else if (child.type === 'animatedMealCarousel') {
              children.push(makeAnimatedMealCarouselComponent(child))
            }
          })

          cdnComponents.push({
            id: id.startsWith('TEMP') ? uuidV4() : id,
            properties: {
              children,
              image,
              subtitle: properties.subtitle,
              title: properties.title,
            },
            type,
          })
        }
      })

      downloadJSON({
        filename: `components_${subTerm.id}.json`,
        json: {
          termID: subTerm.termID,
          subTermID: subTerm.id,
          components: cdnComponents,
        },
      })
    })
  }

  const [dialog, setDialog] = useState<
    | {
        componentType: DraggableNewComponent['componentType']
        data?: {
          component?: MenuComponentsStandardized[number]
          index?: number
        }
        type: 'componentEdit'
      }
    | {
        data: { component: MenuComponentsStandardized[number]; index: number }
        type: 'componentCopy'
      }
    | {
        data: {
          components: Record<string, MenuComponentStandardizedMealWithExtra[]>
          meal: MealAdmin
        }
        type: 'additionalMealWithExtraEdit'
      }
    | null
  >(null)
  const [viewType, setViewType] = useState<ViewType>('desktop')

  const componentToEdit =
    dialog?.type === 'componentEdit' ? dialog.data?.component : undefined

  return (
    <DndContext
      onDragEnd={({ active, over }) => {
        setActiveID(null)
        setDragOverlayAddComponentType(null)

        if (over) {
          const overIndex = getIndex(over.id)
          if (activeIndex !== overIndex) {
            reorderComponents(activeIndex, overIndex)
          }
        }

        if (active.data.current?.type === 'newComponent') {
          // TODO update type definition
          setDialog({
            componentType: active.data.current?.componentType,
            type: 'componentEdit',
          })
        }
      }}
      onDragOver={({ active, over }) => {
        if (!over) {
          return
        }

        if (active.data.current?.type === 'newComponent') {
          const overIndex = getIndex(over.id)
          if (overIndex !== -1) {
            onInsertPlaceholder(overIndex)
          }
        }
      }}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }

        setActiveID(active.id)

        if (active.data.current?.type === 'newComponent') {
          setDragOverlayAddComponentType(active.data.current?.componentType)
        }
      }}
      sensors={sensors}
    >
      {selectedSubTerm ? (
        <div className="space-y-10">
          <div className="grid h-full grid-cols-[350px_auto] divide-x divide-grey-3">
            <div className={clsx('space-y-8 pr-16', {})}>
              <h1 className="text-k/28_130">
                Term #{selectedSubTerm.termID} Menus
              </h1>

              <Button
                onClick={() => {
                  onClickDownload()
                }}
                size="medium"
              >
                Download Menu Components
              </Button>

              <div className="space-y-2">
                <h2 className="text-k/28_130">Components</h2>
                <p className="text-k/14_120 text-grey-9">
                  Drag a component into the &quot;Preview&quot; pane to add it
                  to the menu.
                </p>
              </div>

              <div className="align-center flex flex-wrap">
                <DraggableNewComponent componentType="backgroundImageHeader">
                  <BackgroundImageHeaderButton />
                </DraggableNewComponent>

                <DraggableNewComponent componentType="textImageStack">
                  <TextImageStackButton />
                </DraggableNewComponent>
              </div>
            </div>

            <div className="flex flex-col space-y-8 pl-16">
              <div className="flex items-center justify-between">
                <h2 className="text-k/28_130">Preview</h2>
                <div className="flex">
                  <ToggleButton
                    isFirst
                    isSelected={viewType === 'desktop'}
                    onClick={() => {
                      setViewType('desktop')
                    }}
                  >
                    Desktop
                  </ToggleButton>
                  <ToggleButton
                    isSelected={viewType === 'mobile'}
                    onClick={() => {
                      setViewType('mobile')
                    }}
                  >
                    Mobile
                  </ToggleButton>
                </div>
              </div>
              <TabGroup
                onChange={onChangeSelectedSubTermIndex}
                selectedIndex={selectedSubTermIndex}
              >
                <TabList>
                  {orderedSubTerms.map(({ facilityNetwork, shipPeriod }) => {
                    const content = `${facilityNetwork} ${shipPeriod}`

                    return (
                      <div key={content} className="text-grey-0">
                        <Tab>
                          <span className="text-k/12_120 uppercase text-black">
                            {content}
                          </span>
                        </Tab>
                      </div>
                    )
                  })}
                </TabList>
                <TabPanels as={Fragment}>
                  <div className="h-px grow overflow-auto pb-24">
                    {orderedSubTerms.map((subterm) => {
                      return (
                        <TabPanel key={subterm.id}>
                          <MenuComponentsGrid
                            activeID={activeID}
                            components={selectedComponents}
                            onClickCopy={(data) => {
                              setDialog({ data, type: 'componentCopy' })
                            }}
                            onClickDelete={(
                              component: MenuComponentStandardized
                            ) => {
                              removeComponent({
                                component,
                                subTermID: selectedSubTerm.id,
                              })
                            }}
                            onClickEdit={(opts) => {
                              setDialog({
                                ...opts,
                                type: 'componentEdit',
                              })
                            }}
                            viewType={viewType}
                          />
                        </TabPanel>
                      )
                    })}
                  </div>
                </TabPanels>
              </TabGroup>
            </div>
          </div>

          {dialog?.type === 'componentEdit' ? (
            <>
              {dialog.componentType === 'backgroundImageHeader' ? (
                <BackgroundImageHeaderDialog
                  initialValues={
                    componentToEdit?.type === 'backgroundImageHeader'
                      ? {
                          image: {
                            filename: undefined,
                            src: componentToEdit.properties.image.url,
                          },
                          subtitle: componentToEdit.properties.subtitle,
                          subtitleColor:
                            componentToEdit.properties.subtitleColor ?? '',
                          title: componentToEdit.properties.title,
                          titleColor:
                            componentToEdit.properties.titleColor ?? '',
                        }
                      : undefined
                  }
                  onClose={() => {
                    onClearPlaceholder()
                    setDialog(null)
                  }}
                  onSave={(data) => {
                    const filename = data.image.filename
                    if (filename) {
                      setImageFilenames((imageFilenames) => {
                        const newFilenames = new Map(imageFilenames)
                        newFilenames.set(data.image.src, filename)

                        return newFilenames
                      })
                    }

                    const component: MenuComponentStandardizedBackgroundImageHeader =
                      {
                        id: uuidV4(),
                        properties: {
                          image: {
                            url: data.image.src,
                          },
                          subtitle: data.subtitle,
                          subtitleColor: data.subtitleColor,
                          title: data.title,
                          titleColor: data.titleColor,
                        },
                        type: 'backgroundImageHeader',
                      }

                    if (dialog.data?.component) {
                      updateComponent({
                        component: {
                          ...component,
                          id: dialog.data.component.id,
                        },
                        subTermID: selectedSubTerm.id,
                      })
                    } else {
                      addComponent({
                        component,
                        index: dialog.data?.index,
                        subTermID: selectedSubTerm.id,
                      })
                    }

                    onClearPlaceholder()
                    setDialog(null)
                  }}
                />
              ) : dialog.componentType === 'textImageStack' ? (
                <TextImageStackDialog
                  components={selectedComponents}
                  initialValues={
                    componentToEdit?.type === 'textImageStack'
                      ? {
                          associatedComponentIDs: new Set(
                            componentToEdit.properties.children.map(
                              ({ id }) => id
                            )
                          ),
                          image: componentToEdit.properties.image
                            ? {
                                filename: undefined,
                                src: componentToEdit.properties.image.url,
                              }
                            : undefined,
                          subtitle: componentToEdit.properties.subtitle,
                          title: componentToEdit.properties.title,
                        }
                      : { associatedComponentIDs: new Set(), title: '' }
                  }
                  onClose={() => {
                    onClearPlaceholder()
                    setDialog(null)
                  }}
                  onSave={(data) => {
                    const filename = data.image?.filename
                    if (filename) {
                      setImageFilenames((imageFilenames) => {
                        const newFilenames = new Map(imageFilenames)

                        if (data.image?.src) {
                          newFilenames.set(data.image.src, filename)
                        }

                        return newFilenames
                      })
                    }

                    let previousChildren: MenuComponentStandardizedTextImageStackChildren =
                      []

                    if (
                      dialog.data?.component &&
                      dialog.data.component.type === 'textImageStack'
                    ) {
                      previousChildren = [
                        ...dialog.data.component.properties.children,
                      ]
                    }

                    const component: MenuComponentStandardizedTextImageStack = {
                      id: uuidV4(),
                      properties: {
                        children: compact(
                          Array.from(data.associatedComponentIDs).map(
                            (componentID) => {
                              const component = [
                                ...previousChildren,
                                ...selectedComponents,
                              ].find(
                                (component) => component.id === componentID
                              )

                              if (
                                isMealComponent(component) ||
                                isTwoMealPickerComponent(component) ||
                                isMealCarouselComponent(component) ||
                                isMealWithExtraComponent(component)
                              ) {
                                return component
                              }
                            }
                          )
                        ),
                        image: data.image
                          ? {
                              url: data.image.src,
                            }
                          : undefined,
                        subtitle: data.subtitle,
                        title: data.title,
                      },
                      type: 'textImageStack',
                    }

                    if (
                      dialog.data?.component &&
                      dialog.data.component.type === 'textImageStack'
                    ) {
                      updateComponent({
                        component: {
                          ...component,
                          id: dialog.data.component.id,
                        },
                        previousChildren:
                          dialog.data.component.properties.children,
                        subTermID: selectedSubTerm.id,
                      })
                    } else {
                      addComponent({
                        component,
                        index: dialog.data?.index,
                        subTermID: selectedSubTerm.id,
                      })
                    }

                    onClearPlaceholder()
                    setDialog(null)
                  }}
                />
              ) : dialog.componentType === 'mealWithExtra' ? (
                <MealWithExtraDialog
                  initialValues={
                    componentToEdit?.type === 'mealWithExtra'
                      ? {
                          detailsMealID:
                            componentToEdit.properties.mealOption.detailsMealID,
                        }
                      : undefined
                  }
                  onClose={() => {
                    setDialog(null)
                  }}
                  onSave={(data, meal) => {
                    if (
                      dialog.data?.component &&
                      dialog.data.component.type === 'mealWithExtra' &&
                      meal
                    ) {
                      const componentID = dialog.data.component.id

                      const url = getMealImageURL(meal)

                      const component: MenuComponentStandardizedMealWithExtra =
                        {
                          ...dialog.data.component,
                          properties: {
                            ...dialog.data.component.properties,
                            mealOption: {
                              ...dialog.data.component.properties.mealOption,
                              detailsMealID: data.detailsMealID,
                              meal: {
                                ...dialog.data.component.properties.mealOption
                                  .meal,
                                image: {
                                  url,
                                },
                              },
                            },
                          },
                        }

                      updateComponent({
                        component,
                        subTermID: selectedSubTerm.id,
                      })

                      // Find other mealWithExtra components that have the same extra and
                      // haven't already been updated with the termless meal ID that was selected
                      // so we can prompt the user to update the other matching extras
                      const componentsWithMatchingExtra: Record<
                        string,
                        MenuComponentStandardizedMealWithExtra[]
                      > = {}

                      if (meal) {
                        orderedSubTerms.forEach((subTerm) => {
                          componentsWithMatchingExtra[subTerm.id] = components[
                            subTerm.id
                          ]
                            .filter((component) => {
                              if (
                                component.type === 'mealWithExtra' &&
                                component.id !== componentID &&
                                component.properties.mealOption
                                  .detailsMealID !== meal.id &&
                                component.properties.mealOption.meal.mealSummary.shortSubtitle.includes(
                                  meal.title
                                )
                              ) {
                                return component
                              }
                            })
                            .filter(
                              (
                                component
                              ): component is MenuComponentStandardizedMealWithExtra =>
                                !!component
                            )
                        })
                      }

                      if (
                        !isEmpty(componentsWithMatchingExtra) &&
                        flatMap(
                          componentsWithMatchingExtra,
                          (components) => components
                        ).length > 0
                      ) {
                        setDialog({
                          data: {
                            components: componentsWithMatchingExtra,
                            meal,
                          },
                          type: 'additionalMealWithExtraEdit',
                        })
                      } else {
                        setDialog(null)
                      }
                    }
                  }}
                />
              ) : null}
            </>
          ) : dialog?.type === 'componentCopy' ? (
            <CopyComponentDialog
              onClose={() => {
                setDialog(null)
              }}
              onSave={(subTermIDs) => {
                subTermIDs.forEach((subTermID) => {
                  addComponent({
                    component: { ...dialog.data.component, id: uuidV4() },
                    index: dialog.data.index,
                    subTermID,
                  })
                })
                setDialog(null)
              }}
              subTerms={orderedSubTerms.filter(
                (subTerm) => subTerm.id !== selectedSubTerm.id
              )}
            />
          ) : dialog?.type === 'additionalMealWithExtraEdit' ? (
            <EditAdditionalMealWithExtraDialog
              components={dialog.data.components}
              meal={dialog.data.meal}
              onClose={() => {
                setDialog(null)
              }}
              onSave={(components, meal) => {
                const url = getMealImageURL(meal)

                forEach(components, (componentsForSubTerm, subTermID) => {
                  forEach(componentsForSubTerm, (component) => {
                    const updatedComponent: MenuComponentStandardizedMealWithExtra =
                      {
                        ...component,
                        properties: {
                          ...component.properties,
                          mealOption: {
                            ...component.properties.mealOption,
                            detailsMealID: meal.id,
                            meal: {
                              ...component.properties.mealOption.meal,
                              image: {
                                url,
                              },
                            },
                          },
                        },
                      }

                    updateComponent({
                      component: updatedComponent,
                      subTermID,
                    })
                  })
                })

                setDialog(null)
              }}
              orderedSubTerms={orderedSubTerms}
            />
          ) : null}
        </div>
      ) : null}

      <DragOverlay>
        {activeID ? (
          <div className="max-w-fit">
            {activeComponent && activeComponent.type !== 'dropPlaceholder' ? (
              <div className="rounded-xl bg-grey-0">
                {activeComponent.type === 'backgroundImageHeader' ? (
                  <div className="h-96 w-[350px]">
                    <BackgroundImageHeader
                      properties={activeComponent.properties}
                    />
                  </div>
                ) : activeComponent.type === 'meal' ? (
                  <Meal properties={activeComponent.properties} />
                ) : activeComponent.type === 'mealWithExtra' ? (
                  <MealWithExtra properties={activeComponent.properties} />
                ) : activeComponent.type === 'twoMealPicker' ? (
                  <TwoMealPicker properties={activeComponent.properties} />
                ) : activeComponent.type === 'animatedMealCarousel' ? (
                  <MealCarousel properties={activeComponent.properties} />
                ) : activeComponent.type === 'textBanner' ? (
                  <TextBanner properties={activeComponent.properties} />
                ) : activeComponent.type === 'textImageStack' ? (
                  <TextImageStack
                    properties={activeComponent.properties}
                    viewType={viewType}
                  />
                ) : null}
              </div>
            ) : (
              <div>
                {dragOverlayAddComponentType ? (
                  <div>
                    {dragOverlayAddComponentType === 'backgroundImageHeader' ? (
                      <BackgroundImageHeaderButton />
                    ) : dragOverlayAddComponentType === 'textImageStack' ? (
                      <TextImageStackButton />
                    ) : null}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}

const ToggleButton = ({
  children,
  isFirst = false,
  isSelected,
  onClick,
}: {
  children: ReactNode
  isFirst?: boolean
  isSelected: boolean
  onClick(): void
}) => {
  const marginClass = isFirst ? '-mr-12' : '-ml-12'
  const paddingClass = isFirst ? 'pr-8' : 'pl-8'

  return (
    <div
      className={clsx({
        [`z-10 ${marginClass}`]: isSelected,
      })}
    >
      <Button
        buttonStyle={isSelected ? 'dark' : 'gray'}
        onClick={() => {
          onClick()
        }}
        size="medium"
      >
        <span
          className={clsx({
            [`${paddingClass}`]: !isSelected,
          })}
        >
          {children}
        </span>
      </Button>
    </div>
  )
}

const BackgroundImageHeaderButton = () => {
  return (
    <button className="flex w-24 flex-col items-center space-y-1 text-center">
      <div className="flex h-10 w-10 flex-col items-center justify-center space-y-1 bg-grey-3">
        <div className="h-1 w-1/2 bg-grey-4" />
        <div className="h-1 w-3/4 bg-grey-4" />
      </div>
      <p className="text-k/13_120">Background Image Header</p>
    </button>
  )
}

const TextImageStackButton = () => {
  return (
    <button className="flex w-24 flex-col items-center space-y-1 text-center">
      <div className="flex h-10 w-10 flex-col items-center justify-center space-y-1 bg-grey-3">
        <div className="flex w-3/4 justify-between space-x-1">
          <div className="h-2 grow bg-grey-4" />
          <div className="h-2 w-2 bg-grey-4" />
        </div>
        <div className="h-1 w-3/4 bg-grey-4" />
        <div className="grid grid-cols-2 gap-1">
          <div className="h-2 w-2 bg-grey-4" />
          <div className="h-2 w-2 bg-grey-4" />
        </div>
      </div>
      <p className="text-k/13_120">Text Image Stack</p>
    </button>
  )
}

const ImageUpload = ({
  hasError = false,
  onBlur,
  onImageAdded,
}: {
  hasError?: boolean
  onBlur(): void
  onImageAdded(opts: { filename: string; src: string }): void
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => {
      const file = files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          if (typeof reader.result === 'string') {
            onImageAdded({ filename: file.name, src: reader.result })
          }
        },
        false
      )

      reader.readAsDataURL(files[0])
    },
  })

  return (
    <div
      className={clsx('border border-dashed p-8 text-center', {
        'text-black': isDragActive,
        'text-grey-6': !isDragActive,
        'border-current': !hasError,
        'border-red': hasError,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps({ onBlur })} />
      <p>Drop an image here or click to select one</p>
    </div>
  )
}

interface BackgroundImageHeaderFormData {
  image: { filename: string | undefined; src: string }
  subtitle: string
  subtitleColor: string
  title: string
  titleColor: string
}

const BackgroundImageHeaderDialog = ({
  initialValues,
  onClose,
  onSave,
}: {
  initialValues: BackgroundImageHeaderFormData | undefined
  onClose(): void
  onSave(data: BackgroundImageHeaderFormData): void
}) => {
  const { control, formState, handleSubmit, register, setValue, watch } =
    useForm<BackgroundImageHeaderFormData>({
      defaultValues: initialValues,
    })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => {
      const file = files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          if (typeof reader.result === 'string') {
            setValue(
              'image',
              { filename: file.name, src: reader.result },
              { shouldValidate: true }
            )
          }
        },
        false
      )

      reader.readAsDataURL(files[0])
    },
  })

  const image = watch('image')

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Background Image Header</ModalHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <div className="grid grid-cols-[1fr_120px] gap-4">
              <FormGroup label="Image">
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => (
                    <div className="space-y-1">
                      <div>
                        <div
                          className={clsx(
                            'border border-dashed p-8 text-center',
                            {
                              'text-black': isDragActive,
                              'text-grey-6': !isDragActive,
                              'border-current': !formState.errors.image,
                              'border-red': !!formState.errors.image,
                            }
                          )}
                          {...getRootProps()}
                        >
                          <input
                            {...getInputProps({
                              onBlur: field.onBlur,
                              onChange: field.onChange,
                            })}
                          />
                          <p>Drop an image here or click to select one</p>
                        </div>
                      </div>

                      {formState.errors.image?.message && (
                        <FormFieldError>
                          {formState.errors.image.message}
                        </FormFieldError>
                      )}
                    </div>
                  )}
                  rules={{
                    required: 'Please provide an image',
                  }}
                />
              </FormGroup>
              <FormGroup label="Preview">
                <img src={image?.src} />
              </FormGroup>

              <FormGroup error={formState.errors.title?.message} label="Title">
                <Input
                  hasError={!!formState.errors.title}
                  type="text"
                  {...register('title', { required: 'Please enter a title' })}
                />
              </FormGroup>
              <FormGroup label="Title Color">
                <Input type="color" {...register('titleColor')} />
              </FormGroup>
              <FormGroup label="Subtitle">
                <Textarea rows={4} {...register('subtitle')} />
              </FormGroup>
              <FormGroup label="Subtitle Color">
                <Input type="color" {...register('subtitleColor')} />
              </FormGroup>
            </div>
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Save
            </Button>
            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const DraggableNewComponent = ({
  children,
  componentType,
}: {
  children: ReactNode
  componentType: DraggableNewComponent['componentType']
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: componentType,
    data: {
      componentType,
      type: 'newComponent',
    },
  })
  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      {children}
    </div>
  )
}

interface MealWithExtraFormData {
  detailsMealID: number
}

const MealWithExtraDialog = ({
  initialValues,
  onClose,
  onSave,
}: {
  initialValues: MealWithExtraFormData | undefined
  onClose(): void
  onSave(data: MealWithExtraFormData, meal: MealAdmin | undefined): void
}) => {
  const [mealID, setMealID] = useState<number | undefined>(() => {
    return initialValues?.detailsMealID
  })

  const { formState, getValues, handleSubmit, register } =
    useForm<MealWithExtraFormData>({
      defaultValues: initialValues,
    })

  const {
    data: meal,
    error: getMealError,
    isError: hasGetMealError,
    isLoading: isLoadingMeal,
  } = useGetMeal({ mealID })

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Meal With Extra</ModalHeader>
      <form onSubmit={handleSubmit((data) => onSave(data, meal))}>
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <div className="grid grid-cols-[1fr_120px] gap-4">
              <div className="col-span-2">
                <div className="flex items-end justify-between space-x-4">
                  <div className="grow">
                    <FormGroup
                      error={formState.errors.detailsMealID?.message}
                      label="Extra Details Meal ID"
                    >
                      <Input
                        hasError={!!formState.errors.detailsMealID}
                        type="number"
                        {...register('detailsMealID', {
                          required: 'Please enter a meal ID',
                          valueAsNumber: true,
                        })}
                      />
                    </FormGroup>
                  </div>

                  <ButtonLoading
                    buttonStyle="gray"
                    isLoading={isLoadingMeal}
                    onClick={() => {
                      const values = getValues()
                      setMealID(values.detailsMealID)
                    }}
                    size="large"
                    type="button"
                  >
                    Search
                  </ButtonLoading>
                </div>
              </div>
            </div>

            {hasGetMealError && (
              <APIErrorDisplay
                error={getMealError}
                errorCodeMessageMap={GET_MEAL_ERRORS}
              />
            )}

            {meal && (
              <div className="flex items-center space-x-4">
                <img
                  className="h-16 rounded-md"
                  src={
                    meal.images.find((image) => image.key === 'cell_tile')?.url
                  }
                />
                <div>
                  <div>{meal.title}</div>
                  <div className="text-sm">{meal.subtitle}</div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button disabled={!meal} size="large" type="submit">
              Save
            </Button>

            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const EditAdditionalMealWithExtraDialog = ({
  components,
  meal,
  onClose,
  onSave,
  orderedSubTerms,
}: {
  components: Record<string, MenuComponentStandardizedMealWithExtra[]>
  meal: MealAdmin
  onClose(): void
  onSave(
    components: Record<string, MenuComponentStandardizedMealWithExtra[]>,
    meal: MealAdmin
  ): void
  orderedSubTerms: TermSubTerm[]
}) => {
  const [componentIDs, setComponentIDs] = useState(
    new Set<string>(
      flatMap(components, (components) => {
        return components.map((component) => component.id)
      })
    )
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>
        Additional Matching Extras
      </ModalHeader>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          const componentsToUpdate: Record<
            string,
            MenuComponentStandardizedMealWithExtra[]
          > = {}

          forEach(components, (componentsForSubTerm, subTermID) => {
            const updatedComponents = componentsForSubTerm.filter((component) =>
              componentIDs.has(component.id)
            )
            componentsToUpdate[subTermID] = updatedComponents
          })

          onSave(componentsToUpdate, meal)
        }}
      >
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <p>Select the components you'd like to update with {meal.title}.</p>
            {map(components, (components, subTermID) => {
              const subTerm = orderedSubTerms.find(
                (subTerm) => subTerm.id === subTermID
              )

              const label = subTerm
                ? `${subTerm.facilityNetwork} ${subTerm.shipPeriod}`
                : ''

              return (
                <div key={subTermID} className="space-y-4">
                  <div className="text-k/12_120 uppercase text-black">
                    {label}
                  </div>
                  {components.map((component) => {
                    return (
                      <div key={component.id}>
                        <Checkbox
                          checked={componentIDs.has(component.id)}
                          label={
                            <div className="flex items-center space-x-4">
                              <img
                                className="h-16 rounded-md"
                                src={
                                  component.properties.mealOption.meal.image.url
                                }
                              />
                              <div>
                                <div>
                                  {component.properties.mealOption.meal.title}
                                </div>
                                <div className="text-sm">
                                  {
                                    component.properties.mealOption.meal
                                      .subtitle
                                  }
                                </div>
                              </div>
                            </div>
                          }
                          name={component.id}
                          onChange={() => {
                            setComponentIDs((componentIDs) => {
                              const newComponentIDs = new Set(componentIDs)

                              if (newComponentIDs.has(component.id)) {
                                newComponentIDs.delete(component.id)
                              } else {
                                newComponentIDs.add(component.id)
                              }

                              return newComponentIDs
                            })
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Save
            </Button>

            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

interface TextImageStackFormData {
  associatedComponentIDs: Set<string>
  image?: { filename: string | undefined; src: string }
  subtitle?: string
  title: string
}

const TextImageStackDialog = ({
  components,
  initialValues,
  onClose,
  onSave,
}: {
  components: (ComponentDropPlaceholder | MenuComponentStandardized)[]
  initialValues: TextImageStackFormData | undefined
  onClose(): void
  onSave(data: TextImageStackFormData): void
}) => {
  const { control, formState, handleSubmit, register, setValue, watch } =
    useForm<TextImageStackFormData>({
      defaultValues: initialValues,
    })

  const associatedComponentIDs = watch('associatedComponentIDs')
  const image = watch('image')

  const associatedMealsOptions = compact(
    components.map((component) => {
      // If the component has already been chosen as an associated component,
      // don't display it as an option to choose again.
      if (associatedComponentIDs.has(component.id)) {
        return
      }

      if (component.type === 'meal') {
        return {
          label: component.properties.title,
          value: component.id,
        }
      } else if (component.type === 'mealWithExtra') {
        return {
          label: component.properties.meal.title,
          value: component.id,
        }
      } else if (component.type === 'twoMealPicker') {
        return {
          label: component.properties.meals[0].title,
          value: component.id,
        }
      } else if (component.type === 'animatedMealCarousel') {
        return {
          label: component.properties.mealOptions[0].title,
          value: component.id,
        }
      }
    })
  )

  // Some components have children that could be meal components we want to
  // search through to display a preview. This will happen with an existing
  // textImageStack - we want to show that the children meals have been chosen.
  const flatComponents = components.flatMap((component) => {
    if (component.type === 'textImageStack') {
      return component.properties.children
    }

    return component
  })

  const associatedMealPreviews = compact(
    Array.from(associatedComponentIDs).map((componentID) => {
      const component = flatComponents.find(
        (component) => component.id === componentID
      )
      if (!component) {
        return
      }

      if (component.type === 'meal') {
        return {
          id: component.id,
          image: component.properties.image,
          subtitle: component.properties.subtitle,
          title: component.properties.title,
        }
      } else if (component.type === 'mealWithExtra') {
        return {
          id: component.id,
          image: component.properties.meal.image,
          subtitle: component.properties.meal.subtitle,
          title: component.properties.meal.title,
        }
      } else if (component.type === 'twoMealPicker') {
        return {
          id: component.id,
          image: component.properties.meals[0].image,
          subtitle: component.properties.meals[0].subtitle,
          title: component.properties.meals[0].title,
        }
      } else if (component.type === 'animatedMealCarousel') {
        return {
          id: component.id,
          image: component.properties.mealOptions[0].image,
          subtitle: component.properties.mealOptions[0].subtitle,
          title: component.properties.mealOptions[0].title,
        }
      }
    })
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Text Image Stack</ModalHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <div className="grid grid-cols-[1fr_120px] gap-4">
              <FormGroup label="Image">
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => (
                    <ImageUpload
                      onBlur={field.onBlur}
                      onImageAdded={(data) => {
                        setValue('image', data)
                      }}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup label="Preview">
                <div className="group relative">
                  <img src={image?.src} />

                  <div className="absolute inset-0 hidden items-center justify-center bg-grey-3/60 py-4 group-hover:flex">
                    <ButtonRound
                      buttonSize="small"
                      buttonStyle="dark"
                      icon={<TrashIcon />}
                      label="Delete"
                      onClick={() => {
                        setValue('image', undefined)
                      }}
                    />
                  </div>
                </div>
              </FormGroup>

              <div className="col-span-2">
                <FormGroup
                  error={formState.errors.title?.message}
                  label="Title"
                >
                  <Input
                    hasError={!!formState.errors.title}
                    type="text"
                    {...register('title', { required: 'Please enter a title' })}
                  />
                </FormGroup>
              </div>
              <div className="col-span-2">
                <FormGroup label="Subtitle">
                  <Textarea rows={4} {...register('subtitle')} />
                </FormGroup>
              </div>
              <div className="col-span-2 space-y-4">
                <FormGroup label="Associated Meals">
                  <Controller
                    control={control}
                    name="associatedComponentIDs"
                    render={() => (
                      <div className="space-y-1">
                        <Listbox
                          onChange={(option) => {
                            if (option) {
                              const newAssociatedComponentIDs = new Set(
                                associatedComponentIDs
                              )
                              newAssociatedComponentIDs.add(option.value)

                              setValue(
                                'associatedComponentIDs',
                                newAssociatedComponentIDs,
                                { shouldValidate: true }
                              )
                            }
                          }}
                          options={associatedMealsOptions}
                          value={null}
                        />

                        {formState.errors.associatedComponentIDs?.message && (
                          <FormFieldError>
                            {formState.errors.associatedComponentIDs.message}
                          </FormFieldError>
                        )}
                      </div>
                    )}
                    rules={{
                      required: 'Please choose associated meals',
                    }}
                  />
                </FormGroup>

                {associatedMealPreviews.map(
                  ({ id, image, subtitle, title }) => {
                    return (
                      <div
                        key={id}
                        className="flex items-center justify-between space-x-4"
                      >
                        <div className="flex items-center space-x-4">
                          <img className="h-16 rounded-md" src={image.url} />
                          <div>
                            <div>{title}</div>
                            <div className="text-sm">{subtitle}</div>
                          </div>
                        </div>

                        <button
                          className="h-5 w-5"
                          onClick={() => {
                            const newAssociatedComponentIDs = new Set(
                              associatedComponentIDs
                            )
                            newAssociatedComponentIDs.delete(id)

                            setValue(
                              'associatedComponentIDs',
                              newAssociatedComponentIDs
                            )
                          }}
                          type="button"
                        >
                          <XIcon />
                        </button>
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Save
            </Button>
            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const CopyComponentDialog = ({
  onClose,
  onSave,
  subTerms,
}: {
  onClose(): void
  onSave(subTermIDs: Set<string>): void
  subTerms: TermSubTerm[]
}) => {
  const [subTermIDs, setSubTermIDs] = useState(
    new Set<string>(subTerms.map((subTerm) => subTerm.id))
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Copy to Other Menus</ModalHeader>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          onSave(subTermIDs)
        }}
      >
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            {subTerms.map((subTerm) => {
              const label = `${subTerm.facilityNetwork} ${subTerm.shipPeriod}`

              return (
                <div key={subTerm.id} className="flex">
                  <Checkbox
                    checked={subTermIDs.has(subTerm.id)}
                    label={
                      <span className="text-k/13_120 uppercase">{label}</span>
                    }
                    name={label}
                    onChange={() => {
                      setSubTermIDs((subTermIDs) => {
                        const newSubTermIDs = new Set(subTermIDs)

                        if (newSubTermIDs.has(subTerm.id)) {
                          newSubTermIDs.delete(subTerm.id)
                        } else {
                          newSubTermIDs.add(subTerm.id)
                        }

                        return newSubTermIDs
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Copy
            </Button>
            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const DuplicateDocumentsIcon = () => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MenuGridLayout = ({
  children,
  viewType,
}: {
  children: ReactNode
  viewType: ViewType
}) => {
  return (
    <div
      className={clsx('grid', {
        'w-[780px] grid-cols-2 gap-x-8 gap-y-12': viewType === 'desktop',
        'w-[350px] grid-cols-1 gap-12': viewType === 'mobile',
      })}
    >
      {children}
    </div>
  )
}

const MenuComponentsGrid = ({
  activeID,
  components,
  onClickCopy,
  onClickDelete,
  onClickEdit,
  viewType,
}: {
  activeID: UniqueIdentifier | null
  components: (ComponentDropPlaceholder | MenuComponentStandardized)[]
  onClickCopy(opts: {
    component: MenuComponentStandardized
    index: number
  }): void
  onClickDelete(component: MenuComponentStandardized): void
  onClickEdit(opts: {
    componentType: DraggableNewComponent['componentType']
    data: { component: MenuComponentStandardized; index: number }
  }): void
  viewType: ViewType
}) => {
  return (
    <SortableContext items={components} strategy={rectSortingStrategy}>
      <MenuGridLayout viewType={viewType}>
        {components.map((component, index) => {
          if (component.type === 'dropPlaceholder') {
            return (
              <div
                key={index}
                className="flex h-full min-h-[350px] w-full items-center justify-center rounded-2xl border border-dashed border-grey-6"
              >
                Drop Component Here
              </div>
            )
          }

          return (
            <SortableComponent
              key={component.id}
              cols={
                // We only have two columns on desktop. On mobile it's a single column
                // so everything stretches just one column.
                (component.type === 'textImageStack' ||
                  component.type === 'textBanner') &&
                viewType === 'desktop'
                  ? 'col-span-2'
                  : 'col-span-1'
              }
              componentID={component.id}
              isDragging={activeID === component.id}
            >
              {component.type === 'backgroundImageHeader' ? (
                <div className="group relative h-full min-h-[24rem] w-full">
                  <BackgroundImageHeader properties={component.properties} />

                  <div className="absolute bottom-0 left-0 right-0 z-10 hidden justify-center space-x-4 rounded-b-2xl bg-grey-3/60 py-4 group-hover:flex">
                    <ButtonRound
                      buttonSize="large"
                      buttonStyle="dark"
                      icon={<PencilIcon />}
                      label="Edit"
                      onClick={() => {
                        onClickEdit({
                          componentType: 'backgroundImageHeader',
                          data: { component, index },
                        })
                      }}
                    />
                    <ButtonRound
                      buttonSize="large"
                      buttonStyle="dark"
                      icon={<DuplicateDocumentsIcon />}
                      label="Copy"
                      onClick={() => {
                        onClickCopy({ component, index })
                      }}
                    />
                    <ButtonRound
                      buttonSize="large"
                      buttonStyle="dark"
                      icon={<TrashIcon />}
                      label="Delete"
                      onClick={() => {
                        onClickDelete(component)
                      }}
                    />
                  </div>
                </div>
              ) : component.type === 'meal' ? (
                <Meal properties={component.properties} />
              ) : component.type === 'mealWithExtra' ? (
                <div className="group relative">
                  <MealWithExtra properties={component.properties} />

                  <div className="absolute left-0 right-0 top-0 z-10 hidden justify-center space-x-4 rounded-b-2xl bg-grey-3/60 py-4 group-hover:flex">
                    <ButtonRound
                      buttonSize="large"
                      buttonStyle="dark"
                      icon={<PencilIcon />}
                      label="Edit"
                      onClick={() => {
                        onClickEdit({
                          componentType: 'mealWithExtra',
                          data: { component, index },
                        })
                      }}
                    />
                  </div>
                </div>
              ) : component.type === 'twoMealPicker' ? (
                <div className="md:px-4">
                  <TwoMealPicker properties={component.properties} />
                </div>
              ) : component.type === 'animatedMealCarousel' ? (
                <MealCarousel properties={component.properties} />
              ) : component.type === 'textBanner' ? (
                <TextBanner properties={component.properties} />
              ) : component.type === 'textImageStack' ? (
                <div className="group relative col-span-2 md:col-span-1">
                  <div className="absolute left-0 right-0 top-0 z-10 hidden justify-center space-x-4 bg-grey-3/60 py-4 group-hover:flex">
                    <ButtonRound
                      buttonSize="large"
                      buttonStyle="dark"
                      icon={<PencilIcon />}
                      label="Edit"
                      onClick={() => {
                        onClickEdit({
                          componentType: 'textImageStack',
                          data: { component, index },
                        })
                      }}
                    />
                    <ButtonRound
                      buttonSize="large"
                      buttonStyle="dark"
                      icon={<TrashIcon />}
                      label="Delete"
                      onClick={() => {
                        onClickDelete(component)
                      }}
                    />
                  </div>

                  <TextImageStack
                    properties={component.properties}
                    viewType={viewType}
                  />
                </div>
              ) : null}
            </SortableComponent>
          )
        })}
      </MenuGridLayout>
    </SortableContext>
  )
}

const PencilIcon = () => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const SortableComponent = ({
  children,
  cols,
  componentID,
  isDragging,
}: {
  children: ReactNode
  cols: string
  componentID: string
  isDragging: boolean
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: componentID,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      className={`h-full w-full cursor-grab ${cols}`}
      style={style}
      {...attributes}
      {...listeners}
    >
      {!isDragging && children}
    </div>
  )
}

const Meal = ({
  properties,
}: {
  properties: MenuComponentStandardizedMeal['properties']
}) => {
  const { image, subtitle, surcharge, tags, title } = properties
  return (
    <MealComponent
      image={<MealImage image={image} />}
      subtitle={subtitle}
      surcharge={surcharge}
      tags={tags}
      title={title}
    />
  )
}

const TwoMealPicker = ({
  properties,
}: {
  properties: MenuComponentStandardizedTwoMealPicker['properties']
}) => {
  const { meals } = properties
  return (
    <TwoMealPickerComponent
      mealOptions={[
        {
          ...meals[0],
          quantity: 0,
        },
        {
          ...meals[1],
          quantity: 0,
        },
      ]}
    />
  )
}

const MealCarousel = ({
  properties,
}: {
  properties: MenuComponentStandardizedMealCarousel['properties']
}) => {
  const { buttonTitle, mealOptions } = properties
  return (
    <MealCarouselComponent
      buttonTitle={buttonTitle}
      mealOptions={mealOptions.map((mealOption) => {
        return {
          ...mealOption,
          quantity: 0,
        }
      })}
    />
  )
}

const MealWithExtra = ({
  properties,
}: {
  properties: MenuComponentStandardizedMealWithExtra['properties']
}) => {
  const { meal, mealOption } = properties
  const { id, image, surcharge, mealSummary } = mealOption.meal
  return (
    <MealWithExtraComponent
      meal={<Meal properties={meal} />}
      mealOption={{
        id,
        image,
        isDisabled: false,
        isHiddenByFilters: false,
        isSelected: false,
        isSoldOut: false,
        surcharge,
        title: mealSummary.shortSubtitle,
      }}
    />
  )
}

const BackgroundImageHeader = ({
  properties,
}: {
  properties: MenuComponentStandardizedBackgroundImageHeader['properties']
}) => {
  return <BackgroundImageHeaderComponent {...properties} />
}

const TextBanner = ({
  properties,
}: {
  properties: MenuComponentStandardizedTextBanner['properties']
}) => {
  return (
    <div className="text-h/14_110 bg-white p-4 text-center font-semibold">
      {properties.title}
    </div>
  )
}

const TextImageStack = ({
  properties,
  viewType,
}: {
  properties: MenuComponentStandardizedTextImageStack['properties']
  viewType: ViewType
}) => {
  const { children, image, subtitle, title } = properties
  return (
    <TextImageStackComponent image={image} subtitle={subtitle} title={title}>
      <MenuGridLayout viewType={viewType}>
        {children?.map((child) => {
          if (child.type === 'meal') {
            return (
              <div key={`meal-${child.properties.id}`} className="md:px-4">
                <Meal properties={child.properties} />
              </div>
            )
          } else if (child.type === 'mealWithExtra') {
            return (
              <div key={`mealWithExtra-${child.properties.meal.id}`}>
                <MealWithExtra properties={child.properties} />
              </div>
            )
          } else if (child.type === 'twoMealPicker') {
            return (
              <div
                key={`twoMealPicker-${child.properties.meals[0].id}`}
                className="md:px-4"
              >
                <TwoMealPicker properties={child.properties} />
              </div>
            )
          } else if (child.type === 'animatedMealCarousel') {
            return (
              <div key={`mealCarousel-${child.properties.mealOptions[0].id}`}>
                <MealCarousel properties={child.properties} />
              </div>
            )
          }
        })}
      </MenuGridLayout>
    </TextImageStackComponent>
  )
}

function downloadJSON({
  filename,
  json,
}: {
  filename: string
  json: Record<string, unknown>
}) {
  const jsonStr = JSON.stringify(json, null, 2)
  const blob = new Blob([jsonStr], { type: 'application/json' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = filename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  URL.revokeObjectURL(url)
}

interface ComponentDropPlaceholder {
  id: string
  type: 'dropPlaceholder'
}

function useMenuComponentsForSubterm({
  orderedSubTerms,
  placeholderIndex,
  termResolvedMenuComponents,
}: {
  orderedSubTerms: TermSubTerm[]
  placeholderIndex: number | null
  termResolvedMenuComponents: MenuComponentsStandardized[]
}) {
  const [selectedSubTermIndex, setSelectedSubTermIndex] = useState(0)
  const selectedSubTerm = orderedSubTerms[selectedSubTermIndex]

  const [newComponents, setNewComponents] = useState<
    MenuComponentStandardized[]
  >([])
  const [componentsOrdered, setComponentsOrdered] = useState<Record<
    string,
    { id: string; type: string }[]
  > | null>(null)
  const components: Record<
    string,
    (ComponentDropPlaceholder | MenuComponentStandardized)[]
  > = {}

  orderedSubTerms.forEach((subTerm, index) => {
    let componentsForSubTerm: (
      | ComponentDropPlaceholder
      | MenuComponentStandardized
    )[] = []

    const resolvedMenuComponents = termResolvedMenuComponents[index] ?? []

    const allComponents = [...newComponents, ...resolvedMenuComponents]

    const orderForSubTerm = componentsOrdered
      ? componentsOrdered[subTerm.id]
      : resolvedMenuComponents.map((component) => ({
          id: component.id,
          type: component.type,
        }))

    componentsForSubTerm = orderForSubTerm
      .map((orderedComponent) => {
        return allComponents.find(
          (component) => component.id === orderedComponent.id
        )
      })
      .filter(
        (component): component is MenuComponentStandardized => !!component
      )

    components[subTerm.id] = componentsForSubTerm
  })

  let selectedComponents = selectedSubTerm ? components[selectedSubTerm.id] : []

  if (placeholderIndex !== null) {
    selectedComponents = [
      ...selectedComponents.slice(0, placeholderIndex),
      { id: uuidV4(), type: 'dropPlaceholder' },
      ...selectedComponents.slice(placeholderIndex),
    ]
  }

  return {
    addComponent: (newComponent: {
      component: MenuComponentsStandardized[number]
      index?: number
      subTermID: string
    }) => {
      const subTermID = newComponent.subTermID
      const index =
        newComponent.index === undefined
          ? placeholderIndex === null
            ? 0
            : placeholderIndex
          : newComponent.index

      setNewComponents((newComponents) => {
        return [...newComponents, { ...newComponent.component }]
      })

      setComponentsOrdered((prevComponentsOrdered) => {
        let updatedComponents = {}
        if (prevComponentsOrdered === null) {
          orderedSubTerms.forEach((subTerm, index) => {
            updatedComponents[subTerm.id] = termResolvedMenuComponents[
              index
            ].map((component) => {
              return {
                id: component.id,
                type: component.type,
              }
            })
          })
        } else {
          updatedComponents = cloneDeep(prevComponentsOrdered)
        }

        let updatedOrder = [...updatedComponents[subTermID]]

        updatedOrder = [
          ...updatedOrder.slice(0, index),
          { id: newComponent.component.id, type: newComponent.component.type },
          ...updatedOrder.slice(index),
        ]

        if (
          newComponent.component.type === 'textImageStack' &&
          newComponent.component.properties.children.length
        ) {
          newComponent.component.properties.children.forEach((child) => {
            const childIndex = findIndex(
              updatedOrder,
              (component) => component.id === child.id
            )
            if (childIndex !== -1) {
              updatedOrder = updatedOrder.filter(
                (_component, index) => index !== childIndex
              )
            }
          })
        }

        updatedComponents[subTermID] = updatedOrder

        return updatedComponents
      })
    },
    components,
    componentsOrdered: componentsOrdered || components,
    onChangeSelectedSubTermIndex: (index: number) => {
      setSelectedSubTermIndex(index)
    },
    removeComponent: ({
      component,
      subTermID,
    }: {
      component: MenuComponentsStandardized[number]
      subTermID: string
    }) => {
      setComponentsOrdered((prevComponentsOrdered) => {
        let updatedComponents = {}
        if (prevComponentsOrdered === null) {
          orderedSubTerms.forEach((subTerm, index) => {
            updatedComponents[subTerm.id] = termResolvedMenuComponents[
              index
            ].map((component) => {
              return {
                id: component.id,
                type: component.type,
              }
            })
          })
        } else {
          updatedComponents = cloneDeep(prevComponentsOrdered)
        }

        let updatedOrder = [...updatedComponents[subTermID]]

        const componentIndex = findIndex(
          updatedOrder,
          (comp) => comp.id === component.id
        )
        if (componentIndex !== -1) {
          updatedOrder = updatedOrder.filter(
            (_component, index) => index !== componentIndex
          )

          if (
            component.type === 'textImageStack' &&
            component.properties.children.length
          ) {
            updatedOrder = [
              ...updatedOrder.slice(0, componentIndex),
              ...component.properties.children,
              ...updatedOrder.slice(componentIndex),
            ]
          }
        }

        updatedComponents[subTermID] = updatedOrder

        return updatedComponents
      })
    },
    reorderComponents: (activeIndex: number, overIndex: number) => {
      setComponentsOrdered((prevComponentsOrdered) => {
        let updatedComponents = {}
        if (prevComponentsOrdered === null) {
          orderedSubTerms.forEach((subTerm, index) => {
            updatedComponents[subTerm.id] = termResolvedMenuComponents[
              index
            ].map((component) => {
              return {
                id: component.id,
                type: component.type,
              }
            })
          })
        } else {
          updatedComponents = cloneDeep(prevComponentsOrdered)
        }

        const updatedOrder = [...updatedComponents[selectedSubTerm.id]]

        updatedComponents[selectedSubTerm.id] = arrayMove(
          updatedOrder,
          activeIndex,
          overIndex
        )

        return updatedComponents
      })
    },
    selectedComponents,
    selectedSubTerm,
    selectedSubTermIndex,
    updateComponent: (updatedComponent: {
      component: MenuComponentStandardized
      previousChildren?: MenuComponentStandardizedTextImageStack['properties']['children']
      subTermID: string
    }) => {
      setNewComponents((newComponents) => {
        const index = findIndex(
          newComponents,
          (component) => component.id === updatedComponent.component.id
        )

        if (index !== -1) {
          const updatedNewComponents = [...newComponents]

          updatedNewComponents[index] = updatedComponent.component

          return updatedNewComponents
        }

        return [...newComponents, { ...updatedComponent.component }]
      })

      setComponentsOrdered((prevComponentsOrdered) => {
        let updatedComponents = {}
        if (prevComponentsOrdered === null) {
          orderedSubTerms.forEach((subTerm, index) => {
            updatedComponents[subTerm.id] = termResolvedMenuComponents[
              index
            ].map((component) => {
              return {
                id: component.id,
                type: component.type,
              }
            })
          })
        } else {
          updatedComponents = cloneDeep(prevComponentsOrdered)
        }

        let updatedOrder = [...updatedComponents[updatedComponent.subTermID]]

        // If the component is a textImageStack, we need to return any removed children
        // back to the main component order
        if (updatedComponent.component.type === 'textImageStack') {
          if (updatedComponent.previousChildren) {
            updatedOrder = [
              ...updatedComponent.previousChildren,
              ...updatedOrder,
            ]
          }

          if (updatedComponent.component.properties.children) {
            updatedComponent.component.properties.children.forEach((child) => {
              const childIndex = findIndex(
                updatedOrder,
                (component) => component.id === child.id
              )
              if (childIndex !== -1) {
                updatedOrder = updatedOrder.filter(
                  (_component, index) => index !== childIndex
                )
              }
            })
          }
        }

        updatedComponents[updatedComponent.subTermID] = updatedOrder

        return updatedComponents
      })
    },
  }
}

function useOrderedSubTerms({ termID }: { termID: number | undefined }) {
  const { data: term } = useTerm({ termID })
  const subTerms = term?.subTerms ?? []

  const orderedSubTerms = orderBy(subTerms, ['facilityNetwork', 'shipPeriod'])

  return {
    orderedSubTerms,
    term,
  }
}

// Creates temporary component IDs that persist through data refetches
function getComponentTempID(component: MenuComponentStandardized) {
  if (component.type === 'meal') {
    return `TEMP-${component.properties.id}`
  } else if (component.type === 'animatedMealCarousel') {
    return `TEMP-${component.properties.mealOptions[0].id}`
  } else if (component.type === 'mealWithExtra') {
    return `TEMP-${component.properties.meal.id}`
  } else if (component.type === 'twoMealPicker') {
    return `TEMP-${component.properties.meals[0].id}`
  } else if (component.type === 'menuFeedback') {
    return 'TEMP-menuFeedback'
  } else if (component.type === 'textBanner') {
    return 'TEMP-textBanner'
  } else if (component.type === 'suggestions') {
    return 'TEMP-suggestions'
  } else if (component.type === 'backgroundImageHeader') {
    return 'TEMP-backgroundImageHeader'
  }

  return 'TEMP'
}

function useTermResolvedMenuComponents({
  orderedSubTerms,
  termSpecialEvent,
}: {
  orderedSubTerms: TermSubTerm[]
  termSpecialEvent: string | undefined
}) {
  const termMealSummaries = useTermMealSummaries({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
  })

  const isLoadingTermMealSummaries = termMealSummaries.some(
    (termMealSummaries) => termMealSummaries.isLoading
  )

  const termMenuComponents = useTermMenuComponents({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
  })

  const isLoadingTermMenuComponents = termMenuComponents.some(
    (termMenuComponents) => termMenuComponents.isLoading
  )

  const termResolvedMenuComponents = orderedSubTerms.map((subTerm, index) => {
    const meals = termMealSummaries[index]?.data ?? []
    const menuComponents = termMenuComponents[index]?.data?.components ?? []
    const specialEvent = subTerm.specialEvent || termSpecialEvent
    const components = getMenuMealComponents({
      mealSwaps: subTerm.defaultMenu.mealSwaps ?? [],
      meals: orderBy(meals, 'mainDisplayOrder'),
      menuComponents,
      specialEvent,
      suggestions: undefined,
    })

    return components.map((component) => {
      const menuComponent = menuComponents.find(
        (menuComponent) => menuComponent.id === component.id
      )
      return menuComponent
        ? component
        : {
            ...component,
            id: `${getComponentTempID(component)}-${subTerm.id}`,
          }
    })
  })

  return {
    termResolvedMenuComponents:
      !isLoadingTermMealSummaries && !isLoadingTermMenuComponents
        ? termResolvedMenuComponents
        : [],
  }
}

const placeholderMachine = createMachine(
  {
    context: {
      placeholderIndex: null,
      queuedCurrentIndex: -1,
      queuedEntryIndex: -1,
    },
    id: 'placeholderMachine',
    initial: 'idle',
    schema: {
      context: {} as {
        placeholderIndex: number | null
        queuedCurrentIndex: number
        queuedEntryIndex: number
      },
      events: {} as
        | { type: 'clearPlaceholder' }
        | { index: number; type: 'setPlaceholder' },
    },
    tsTypes: {} as import('./TermMenusV2.typegen').Typegen0,

    states: {
      idle: {
        on: {
          clearPlaceholder: { actions: ['clearPlaceholder'] },
          setPlaceholder: {
            actions: ['setQueuedEntryIndex'],
            target: 'waitingToUpdatePlaceholder',
          },
        },
      },
      waitingToUpdatePlaceholder: {
        after: {
          // An arbitrary delay is added to ensure the user is hovering over the final
          // place they want to drop the item. A user could quickly be moving their mouse
          // over items and we don't want to update the layout for every single
          // mouse movement since it can become disorienting.
          200: [
            // If the indexes aren't equal after the delay, then the user dragged
            // their mouse elsewhere and we should start the delay over again.
            {
              actions: ['setPlaceholderIndex'],
              cond: 'areQueuedIndexesEqual',
              target: 'idle',
            },
            {
              actions: ['setQueuedEntryFromCurrent'],
              target: 'waitingToUpdatePlaceholder',
            },
          ],
        },
        on: {
          clearPlaceholder: { actions: ['clearPlaceholder'], target: 'idle' },
          setPlaceholder: { actions: ['setQueuedCurrentIndex'] },
        },
      },
    },
  },
  {
    actions: {
      clearPlaceholder: assign({
        placeholderIndex: null,
        queuedCurrentIndex: -1,
        queuedEntryIndex: -1,
      }),
      setQueuedCurrentIndex: assign({
        queuedCurrentIndex: (_ctx, event) => event.index,
      }),
      setQueuedEntryFromCurrent: assign({
        queuedEntryIndex: (ctx) => ctx.queuedCurrentIndex,
      }),
      setQueuedEntryIndex: assign({
        queuedCurrentIndex: (_ctx, event) => event.index,
        queuedEntryIndex: (_ctx, event) => event.index,
      }),
      setPlaceholderIndex: assign({
        placeholderIndex: (ctx) => ctx.queuedEntryIndex,
        queuedCurrentIndex: -1,
        queuedEntryIndex: -1,
      }),
    },
    guards: {
      areQueuedIndexesEqual: (ctx) =>
        ctx.queuedCurrentIndex === ctx.queuedEntryIndex,
    },
  }
)

// TODO: Most-likely belongs in the @tovala/browser-apis-menu-components shared library.
function isMealComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedMeal {
  return component?.type === 'meal'
}

// TODO: Most-likely belongs in the @tovala/browser-apis-menu-components shared library.
function isTwoMealPickerComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedTwoMealPicker {
  return component?.type === 'twoMealPicker'
}

// TODO: Most-likely belongs in the @tovala/browser-apis-menu-components shared library.
function isMealCarouselComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedMealCarousel {
  return component?.type === 'animatedMealCarousel'
}

// TODO: Most-likely belongs in the @tovala/browser-apis-menu-components shared library.
function isMealWithExtraComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedMealWithExtra {
  return component?.type === 'mealWithExtra'
}

function makeMealComponent(
  component: MenuComponentStandardizedMeal
): MenuComponentMeal {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      mealID: properties.id,
    },
    type,
  }
}

function makeMealWithExtraComponent(
  component: MenuComponentStandardizedMealWithExtra
): MenuComponentMealWithExtra {
  const { id, properties, type } = component

  const url = getValidImageURL(properties.mealOption.meal.image.url)

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      meal: {
        mealID: properties.meal.id,
      },
      mealIDs: [properties.meal.id, properties.mealOption.meal.id],
      mealOption: {
        meal: {
          mealID: properties.mealOption.meal.id,
          image: {
            url,
          },
        },
        detailsMealID: properties.mealOption.detailsMealID,
      },
    },
    type,
  }
}

function makeAnimatedMealCarouselComponent(
  component: MenuComponentStandardizedMealCarousel
): MenuComponentAnimatedMealCarousel {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      buttonTitle: properties.buttonTitle,
      mealOptions: properties.mealOptions.map((mealOption) => {
        return {
          mealID: mealOption.id,
          optionTitle: mealOption.optionTitle,
        }
      }),
    },
    type,
  }
}

function makeTextBannerComponent(
  component: MenuComponentStandardizedTextBanner
): MenuComponentTextBanner {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      title: properties.title,
    },
    type,
  }
}

function makeTwoMealPickerComponent(
  component: MenuComponentStandardizedTwoMealPicker
): MenuComponentTwoMealPicker {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      firstMeal: {
        buttonTitle: properties.meals[0].mealSummary.shortSubtitle,
        meal: {
          mealID: properties.meals[0].id,
        },
      },
      secondMeal: {
        buttonTitle: properties.meals[1].mealSummary.shortSubtitle,
        meal: {
          mealID: properties.meals[1].id,
        },
      },
    },
    type,
  }
}

function getValidImageURL(url: string) {
  if (!url) {
    return url
  }

  return !url.startsWith('http') ? `https:${url}` : url
}

function getMealImageURL(meal: MealAdmin) {
  let url = ''
  const image = meal.images.find((image) => image.key === 'cell_tile')
  if (image) {
    url = getValidImageURL(image.url)
  }
  return url
}
