import { clsx } from 'clsx'
import { isArray, orderBy, uniqBy } from 'lodash-es'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { APIErrorDisplay } from '@tovala/component-library'
import {
  ListingAdmin,
  Term as ITerm,
  useUpdateTermReadyForView,
  useNextTerm,
} from '@tovala/browser-apis-combinedapi'
import { useEffect, useState } from 'react'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { formatCentsToDollars } from 'utils/currency'
import { errorHandler, successHandler } from 'actions/notifications'
import { GET_LISTINGS_FOR_MENUS_ERRORS } from 'utils/errors'
import {
  getAdminScope,
  MEALS_READ,
  MEALS_WRITE,
} from '../../utils/getAdminScope'
import { TermMenuWithFacilityNetwork } from './helpers'

import { useAllTerms } from 'hooks/allTerms'
import { useAppDispatch } from 'hooks'
import { useCreatePublicTermMenus } from 'hooks/combinedAPI/menus'
import { useTermMenuListings } from 'hooks/menuProducts'
import Badge from 'components/common/Badge'
import Button from 'components/common/Button'
import ConfirmationModal from '../modals/ConfirmationModal'
import H2 from 'components/common/H2'
import H3 from 'components/common/H3'
import Loader from 'components/common/Loader'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { TermValidatorButton, TermValidatorSummary } from './TermValidator'

const UTC_OFFSET = moment().isDST() ? -5 : -6

const Meals = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const [showPublicTermDialog, setShowPublicTermDialog] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [termID, setTermID] = useState<number | ''>('')

  const { data: presentTerm } = useNextTerm()

  const { mutate: updateTermReadyForView } = useUpdateTermReadyForView()

  const { mutate: createPublicTermMenus } = useCreatePublicTermMenus({
    onError: (err) => {
      errorHandler(
        dispatch,
        err,
        'Something went wrong pushing the menu changes live. Please contact the tech team.'
      )
    },
    onSuccess: () => {
      successHandler(dispatch, `Success! Menu changes have been pushed live.`)

      setShowPublicTermDialog(false)
    },
  })

  const confirmMarkAsReady = (termID: number) => {
    setTermID(termID)
    setShowModal(true)
  }

  const toggleModal = () => {
    setShowModal((showModal) => !showModal)
  }

  const markTerm = () => {
    if (termID === '') {
      return
    }

    updateTermReadyForView(
      { termID },
      {
        onSuccess: () => {
          successHandler(dispatch, `Success! Term #${termID} is live.`)

          setTermID('')
          setShowModal(false)
        },
      }
    )
  }

  useEffect(() => {
    document.title = `Glaze | Meals`
  }, [])

  return (
    <>
      <div className="flex space-x-8 pb-20">
        <div className="w-7/12">
          <h1 className="mb-4 font-serif text-4xl font-bold">
            Tovala Meal Schedule
          </h1>

          {presentTerm && (
            <>
              <FutureTerms
                confirmMarkAsReady={confirmMarkAsReady}
                presentTerm={presentTerm}
              />

              <PastTerms presentTermID={presentTerm.id} />
            </>
          )}
        </div>

        {getAdminScope(MEALS_READ) && (
          <div className="mt-5 w-4/12 space-y-3">
            {getAdminScope(MEALS_WRITE) && (
              <Link className="block h-10" to={`/meals/add`}>
                <Button buttonStyle="stroke" size="fluid">
                  Add Meal
                </Button>
              </Link>
            )}

            <Link className="block h-10" to={`/meals/in-development`}>
              <Button buttonStyle="grey" size="fluid">
                Meals in Development
              </Button>
            </Link>

            <Link className="block h-10" to="/meal-tags">
              <Button buttonStyle="grey" size="fluid">
                Meal Tags
              </Button>
            </Link>

            {getAdminScope(MEALS_WRITE) && (
              <div className="block h-10">
                <Button
                  buttonStyle="grey"
                  onClick={() => setShowPublicTermDialog(true)}
                  size="fluid"
                >
                  Push Menu Changes Live
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        buttonText="Mark as Ready"
        handleClick={markTerm}
        heading={`Mark as ready: Term #${termID}`}
        isOpen={showModal}
        onCloseModal={toggleModal}
      >
        <div className="space-y-4">
          <p>
            This will set this term&apos;s menu live to customers on the web and
            in the apps.
          </p>
          <p>Are you sure you want to mark this term as ready?</p>
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        buttonText="Yes, push live"
        handleClick={() => {
          createPublicTermMenus()
        }}
        heading={`Push Menu Changes Live`}
        isOpen={showPublicTermDialog}
        onCloseModal={() => setShowPublicTermDialog(false)}
      >
        <div className="space-y-4">
          <p>This will push all menu changes live to the prospect menus.</p>
          <p>Are you sure you want to continue?</p>
        </div>
      </ConfirmationModal>
    </>
  )
}

export default Meals

const Listing = ({
  listing,
  termID,
}: {
  listing: ListingAdmin
  termID: number
}) => {
  const { priceCents, productID, title } = listing
  return (
    <div className="mb-2">
      <h3 className="font-serif text-lg font-bold">
        <div>
          {title}{' '}
          {getAdminScope(MEALS_READ) && (
            <Link
              className="ml-4 text-sm uppercase tracking-widest text-red-901"
              to={`/terms/${termID}/menu-products/${productID}`}
            >
              {getAdminScope(MEALS_WRITE) ? 'Edit' : 'View'}
            </Link>
          )}
        </div>
      </h3>
      <div className="text-sm">{formatCentsToDollars(priceCents)}</div>
    </div>
  )
}

const Meal = ({
  allMenus,
  id,
  menuMeals,
  subtitle,
  surchargeCents,
  title,
}: {
  allMenus: TermMenuWithFacilityNetwork[] | ''
  id: number
  menuMeals?: ITerm['meals'][number]['menuMeals']
  subtitle: string
  surchargeCents?: number
  title: string
}): JSX.Element => {
  return (
    <div className="mb-2">
      <h3 className="font-serif text-lg font-bold">
        <div>
          {title}{' '}
          <span className="text-sm font-normal uppercase">(Meal ID #{id})</span>
          {getAdminScope(MEALS_READ) && (
            <Link
              className="ml-4 text-sm uppercase tracking-widest text-red-901"
              to={`/meals/${id}`}
            >
              {getAdminScope(MEALS_WRITE) ? 'Edit' : 'View'}
            </Link>
          )}
        </div>
      </h3>
      <div className="text-sm">
        {subtitle}{' '}
        {surchargeCents !== undefined && surchargeCents > 0 && (
          <span>| +${surchargeCents / 100}</span>
        )}
      </div>
      {/* Display which menus a meal is on if it's not on all menus */}
      {menuMeals && allMenus && menuMeals.length < allMenus.length && (
        <div className="mt-1 flex space-x-1">
          {menuMeals.map((menuMeal, i) => {
            const menuDetails = allMenus.find(
              (menu) => menu.id === menuMeal.menuID
            )

            if (menuDetails) {
              return (
                <Badge key={i} badgeStyle="grey">
                  {menuDetails.name}
                </Badge>
              )
            }
          })}
        </div>
      )}
    </div>
  )
}

const Term = ({
  isPastTerm = false,
  markTerm,
  term,
  validateTerm,
}: {
  isPastTerm?: boolean
  markTerm?: (termID: number) => void
  term: ITerm
  validateTerm?: boolean
}): JSX.Element => {
  const allMenus = [...term.subTerms]
    .sort(
      (a, b) =>
        a.facilityNetwork.localeCompare(b.facilityNetwork) ||
        a.shipPeriod - b.shipPeriod
    )
    .map((subTerm) =>
      subTerm.menus.map((menu) =>
        Object.assign({}, menu, {
          facilityNetwork: subTerm.facilityNetwork,
          shipPeriod: subTerm.shipPeriod,
        })
      )
    )
    .flat()
    .filter((menu) => menu !== undefined)

  const menuIDs = allMenus.map((menu) => menu.id)

  const {
    error: loadTermMenuListingsError,
    isError: hasLoadTermMenuListingsError,
    isLoading: isLoadingTermMenuListings,
    data: termMenuListings,
  } = useTermMenuListings({ term })

  const allListingsForMenus = termMenuListings
    .map((menuListings) => {
      return menuListings?.listings
    })
    .filter((listings): listings is ListingAdmin[] => !!listings)

  const uniqueProducts = orderBy(
    uniqBy(allListingsForMenus.flat(), 'productID'),
    'productionCode'
  )

  return (
    <div
      key={term.id}
      className={clsx('space-y-2', {
        'bg-grey-908 px-4 py-6': isPastTerm,
      })}
    >
      <H3>
        {formatDate(term.start, {
          format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
        })}{' '}
        &mdash;{' '}
        {formatDate(term.end, {
          format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
        })}
      </H3>

      {term.ready_for_view && (
        <div>
          <span className="text-sm font-semibold text-green-906">
            Term #{term.id} is ready for view.
          </span>
        </div>
      )}

      {!term.ready_for_view && (
        <div>
          <span className="text-sm font-semibold text-red-901">
            Term #{term.id} is not ready for view.
          </span>
          {getAdminScope(MEALS_WRITE) && markTerm && (
            <button
              className="ml-4 text-xs font-semibold uppercase tracking-widest underline"
              onClick={() => markTerm(term.id)}
            >
              Mark as ready.
            </button>
          )}
        </div>
      )}

      {!isPastTerm &&
        !isLoadingTermMenuListings &&
        term.meals &&
        term.meals.length > 0 && (
          <>
            {validateTerm ? (
              <TermValidatorSummary
                term={term}
                termListings={allListingsForMenus.flat()}
                termProducts={uniqueProducts}
              />
            ) : (
              <TermValidatorButton
                term={term}
                termListings={allListingsForMenus.flat()}
                termProducts={uniqueProducts}
              />
            )}
          </>
        )}

      <div
        className={clsx('space-y-4 sticky top-[56px] py-2', {
          'bg-grey-908': isPastTerm,
          'bg-white': !isPastTerm,
        })}
      >
        <strong>Term #{term.id}</strong> / ISO Week #
        {moment(term.start).isoWeek()}
      </div>

      <div>
        <strong>Order by:</strong>{' '}
        {moment(term.order_by)
          .utcOffset(UTC_OFFSET)
          .format('ddd, MMM DD, YYYY @ h:mma')}
      </div>

      {term.meals && term.meals.length > 0 && (
        <div className="mt-4">
          <TabGroup>
            <TabList>
              <Tab>All Items</Tab>
              {allMenus &&
                allMenus.map((menu) => (
                  <Tab key={`tab-${menu.id}`}>
                    {menu.name
                      ? menu.name
                      : `${menu.facilityNetwork}-${menu.shipPeriod}`}
                  </Tab>
                ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <div className="space-y-8">
                  <div>
                    <p className="mb-4">{term.meals.length} Meals</p>
                    {[...term.meals]
                      .sort((a, b) => a.id - b.id)
                      .map((meal, i) => {
                        return (
                          <Meal
                            key={i}
                            allMenus={allMenus}
                            id={meal.id}
                            menuMeals={meal.menuMeals}
                            subtitle={meal.subtitle}
                            surchargeCents={meal.surchargeCents}
                            title={meal.title}
                          />
                        )
                      })}
                  </div>

                  {uniqueProducts.length > 0 && (
                    <div>
                      <p className="mb-4">{uniqueProducts.length} Extras</p>
                      {uniqueProducts.map((listing) => {
                        const menuNames = isArray(allMenus)
                          ? allMenus
                              .map((menu, index) => {
                                const listings = allListingsForMenus[index]

                                if (
                                  listings?.some(
                                    (l) => l.productID === listing.productID
                                  )
                                ) {
                                  return menu.name
                                }
                              })
                              .filter(
                                (menuName): menuName is string => !!menuName
                              )
                          : []

                        return (
                          <div key={listing.productID}>
                            <Listing listing={listing} termID={term.id} />
                            {menuNames.length > 0 &&
                              menuNames.length < menuIDs.length && (
                                <div className="mt-1 flex space-x-1">
                                  {menuNames.map((name) => {
                                    if (name) {
                                      return (
                                        <Badge key={name} badgeStyle="grey">
                                          {name}
                                        </Badge>
                                      )
                                    }
                                  })}
                                </div>
                              )}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </TabPanel>
              {allMenus &&
                allMenus.map((menu, index) => {
                  if (menu.meals) {
                    const listings = orderBy(
                      allListingsForMenus[index],
                      'productionCode'
                    )

                    return (
                      <TabPanel key={menu.id}>
                        <div className="space-y-8">
                          <div>
                            <p className="mb-4">{menu.meals.length} Meals</p>
                            {[...menu.meals]
                              .sort((a, b) => a.id - b.id)
                              .map((meal, i) => {
                                return (
                                  <Meal
                                    key={i}
                                    allMenus={allMenus}
                                    id={meal.id}
                                    subtitle={meal.subtitle}
                                    surchargeCents={meal.surchargeCents}
                                    title={meal.title}
                                  />
                                )
                              })}
                          </div>

                          {listings && listings.length > 0 && (
                            <div>
                              <p className="mb-4">{listings.length} Extras</p>

                              {listings.map((listing) => {
                                return (
                                  <div key={listing.id}>
                                    <Listing
                                      listing={listing}
                                      termID={term.id}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      </TabPanel>
                    )
                  }
                })}
            </TabPanels>
          </TabGroup>
        </div>
      )}

      {hasLoadTermMenuListingsError && (
        <APIErrorDisplay
          error={loadTermMenuListingsError}
          errorCodeMessageMap={GET_LISTINGS_FOR_MENUS_ERRORS}
        />
      )}

      <div className="my-4 flex divide-x divide-grey-905">
        {!term.ready_for_view &&
          term.meals.length === 0 &&
          getAdminScope(MEALS_WRITE) && (
            <Link
              className="pr-2 text-sm font-semibold uppercase tracking-widest text-red-901"
              to={`/terms/${term.id}/create-term-meals`}
            >
              Create Term Meals
            </Link>
          )}

        {getAdminScope(MEALS_READ) &&
          term.meals &&
          moment(term.end).isAfter() &&
          term.meals.length > 0 && (
            <>
              <Link
                className="pr-2 text-sm font-semibold uppercase tracking-widest text-red-901"
                to={`/terms/${term.id}/menus/editor`}
              >
                Menu UI Editor
              </Link>
              <Link
                className="px-2 text-sm font-semibold uppercase tracking-widest text-red-901"
                to={`/terms/${term.id}/menu-display-orders`}
              >
                Menu Display Orders
              </Link>
              <Link
                className="px-2 text-sm font-semibold uppercase tracking-widest text-red-901"
                to={`/terms/${term.id}/customize-it`}
              >
                Customize It
              </Link>
              <Link
                className="px-2 text-sm font-semibold uppercase tracking-widest text-red-901"
                to={`/terms/${term.id}/qr-codes`}
              >
                QR Codes
              </Link>
              <Link
                className="px-2 text-sm font-semibold uppercase tracking-widest text-red-901"
                to={`/terms/${term.id}/sold-out-counts`}
              >
                Sold Out Counts
              </Link>
            </>
          )}

        {getAdminScope(MEALS_WRITE) && allMenus && allMenus.length > 0 && (
          <Link
            className="pl-2 text-sm font-semibold uppercase tracking-widest text-red-901"
            to={`/terms/${term.id}/menus`}
          >
            Edit Menus
          </Link>
        )}
      </div>
    </div>
  )
}

const FutureTerms = ({
  confirmMarkAsReady,
  presentTerm,
}: {
  confirmMarkAsReady(termID: number): void
  presentTerm: ITerm
}) => {
  const initialTermIDs = Array.from(
    { length: 5 },
    (_i, index) => index + 1 + presentTerm.id
  ).reverse()

  const { termsResponse: futureTermsResponse } = useAllTerms({
    opts: { initialTermIDs },
  })

  const futureTerms = futureTermsResponse
    .map((res) => res.data)
    .filter((term): term is ITerm => !!term)
  const isLoadingFutureTerms = futureTermsResponse.some((res) => res.isLoading)

  const termToValidate = !isLoadingFutureTerms
    ? Math.min(
        ...futureTerms
          .filter((term) => !term.ready_for_view)
          .map((term) => term.id)
      )
    : undefined

  if (isLoadingFutureTerms) {
    return (
      <div className="h-full min-h-[300px]">
        <Loader />
      </div>
    )
  }

  return (
    <div className="divide-y-2">
      {futureTerms.map((term) => {
        return (
          <div key={term.id} className="py-6">
            <Term
              markTerm={confirmMarkAsReady}
              term={term}
              validateTerm={term.id === termToValidate}
            />
          </div>
        )
      })}

      <div className="py-6">
        <Term markTerm={confirmMarkAsReady} term={presentTerm} />
      </div>
    </div>
  )
}

const PastTerms = ({ presentTermID }: { presentTermID: number }) => {
  const [showPastTerms, setShowPastTerms] = useState(false)

  const {
    termsResponse: pastTermsResponse,
    termIDs,
    hasMoreTermsAvailable,
    loadMoreTerms,
  } = useAllTerms({
    opts: { startTermID: presentTermID - 1 },
  })

  return (
    <div>
      <div className="mb-6">
        {!showPastTerms && (
          <Button
            buttonStyle="grey"
            onClick={() => {
              setShowPastTerms(true)
              loadMoreTerms()
            }}
            size="large"
          >
            View Past Terms
          </Button>
        )}
      </div>

      {showPastTerms && (
        <div className="space-y-6">
          <H2>Past Terms</H2>

          {pastTermsResponse.map((res, index) => {
            const termID = termIDs[index]

            const {
              data: term,
              isLoading: isLoadingTerm,
              isError: hasLoadTermError,
              error: loadTermError,
            } = res

            if (isLoadingTerm) {
              return (
                <div
                  key={termID}
                  className="bg-grey-908 p-4 space-y-2 h-14 animate-pulse"
                >
                  <p>
                    <strong>Term #{termID}</strong>
                  </p>
                </div>
              )
            }

            if (hasLoadTermError) {
              return (
                <APIErrorDisplay
                  key={termID}
                  error={loadTermError}
                  errorCodeMessageMap={GET_LISTINGS_FOR_MENUS_ERRORS}
                />
              )
            }

            return <Term key={termID} isPastTerm term={term} />
          })}

          {getAdminScope(MEALS_READ) && hasMoreTermsAvailable && (
            <div className="mt-4">
              <Button
                buttonStyle="grey"
                onClick={() => {
                  loadMoreTerms()
                }}
                size="large"
              >
                Show More
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
