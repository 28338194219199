import { omitBy } from 'lodash-es'

import { ReactSelectOptionGroup, ReactSelectValue } from 'types/internal'

function getWholeMinutesFromSeconds({
  timeInSeconds,
  roundUp,
}: {
  timeInSeconds: number
  roundUp?: boolean
}) {
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds - minutes * 60

  return roundUp && seconds > 0 ? minutes + 1 : minutes
}

// Get range of whole minutes in X-Y format
// X is the shorter of the times rounded down to the nearest whole minute
// Y is the longer of the times rounded up to the nearest whole minute
export const getWholeMinutesRangeFromSeconds = ({
  timeInSeconds,
}: {
  timeInSeconds: number[]
}) => {
  if (timeInSeconds.length < 2) {
    return null
  }

  const longestTime = getWholeMinutesFromSeconds({
    timeInSeconds: Math.max(...timeInSeconds),
    roundUp: true,
  })
  const shortestTime = getWholeMinutesFromSeconds({
    timeInSeconds: Math.min(...timeInSeconds),
  })

  // If times are the same, display the second time rounded up 1 minute
  if (shortestTime === longestTime) {
    return `${shortestTime}-${longestTime + 1}`
  }

  return `${shortestTime}-${longestTime}`
}

export function isOptionGroup<Option>(
  options: ReactSelectValue<Option>[] | ReactSelectOptionGroup<Option>[]
): options is ReactSelectOptionGroup<Option>[] {
  return options[0] && 'options' in options[0]
}

export function minutesToMs(minutes: number) {
  return minutes * 60 * 1000
}

export function removeQueryParamsEmptyKeys(
  obj: Record<string, string | null | undefined | ''>
) {
  return omitBy(obj, (value) => {
    return value === undefined || value === ''
  })
}
