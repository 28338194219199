import { ErrorCodeMessageMapCombinedAPI } from '@tovala/browser-apis-combinedapi'
import { ReactNode } from 'react'

import { GoodErrorMessaging } from '@tovala/browser-apis-core'

export const GET_CALENDAR_TERMS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Terms',
    why: "We couldn't load terms due to a technical issue on our end.",
  },
}

export const GET_LISTINGS_FOR_MENUS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Listings for menus',
    why: "We couldn't load listings for this term due to a technical issue on our end.",
  },
}

export const REDEEM_GIFT_CARD_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> =
  {
    Fallback: makeGenericFallbackError({ action: 'apply that gift card' }),
    GiftCardAlreadyRedeemed: {
      wayOut:
        'If you need further assistance, please send a message in the #help-web Slack channel.',
      why: "We couldn't apply that gift card because it has already been redeemed.",
    },
    GiftCardNotFound: {
      helpToFix: 'Please check the entered code and try again.',
      why: "We couldn't apply that gift card because it does not exist.",
    },
  }

export function makeGenericFallbackError({
  action,
  helpToFix = 'Please try again.',
}: {
  action: string
  helpToFix?: string
}) {
  return {
    helpToFix,
    wayOut: (
      <span>
        If the issue persists, please send a message in the #help-web Slack
        channel.
      </span>
    ),
    why: `We couldn't ${action} due to an unknown technical issue.`,
  } satisfies GoodErrorMessaging<ReactNode>
}
